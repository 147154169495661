'use strict';

import {adaptive} from '../adaptive'

document.addEventListener("DOMContentLoaded",()=> {
    const sliderName = 'video-thumbs';
    const sliderSettings = {
        init: true,
        autoplay: false,
        direction: 'horizontal',
        loop: false,
        loopedSlides: 0,
        navigation: {
            nextEl: '.js-' + sliderName + '-next',
            prevEl: '.js-' + sliderName + '-prev',
            disabledClass: 'disabled'
        },
        pagination: false,
        preloadImages: false,
        lazy: true,
        grabCursor: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        freeMode: false,
        containerModifierClass: sliderName +'_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        slideNextClass: sliderName + '__item_next',
        slidePrevClass: sliderName + '__item_prev',
        breakpoints: {
            [adaptive.MD]: {
                direction: 'vertical',
                spaceBetween: 20
            },
            [adaptive.LG]: {
                direction: 'vertical',
                spaceBetween: 30
            }
        }
    };
    window.swiper('.js-video-thumbs', sliderSettings);
});