'use strict';

import {adaptive} from '../adaptive'

document.addEventListener("DOMContentLoaded",()=> {
    const sliderName = 'main-slider';
    const sliderSettings = {
        init: true,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
        },
        centeredSlides: false,
        loop: true,
        loopedSlides: 1,
        effect: "slide",
        speed: 800,
        fadeEffect: {
            crossFade: true
        },
        navigation: false,
        pagination: {
            el: '.js-' + sliderName + '-pagination',
            bulletClass: 'pagination-item',
            bulletActiveClass: 'pagination-item_active',
            bulletElement: 'div',
            clickable: true
        },
        preloadImages: false,
        lazy: true,
        grabCursor: true,
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: false,
        containerModifierClass: sliderName +'_',
        wrapperClass: sliderName + '__wrapper',
        slideClass: sliderName + '__item',
        slideActiveClass: sliderName + '__item_active',
        slideDuplicateActiveClass: sliderName + '__item_duplicate_active',
        slideVisibleClass: sliderName + '__item_visible',
        slideDuplicateClass: sliderName + '__item_duplicate',
        slideNextClass: sliderName + '__item_next',
        slidePrevClass: sliderName + '__item_prev',
        breakpoints: {
            [adaptive.MD]: {
                loopedSlides: 2,
                slidesPerView: 'auto',
            }
        }
    };
    window.swiper('.js-' + sliderName, sliderSettings);
});